import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Layout } from "../components"

const League = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "league.png" }) {
        publicURL
      }
    }
  `)
  return (
    <Layout>
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src={data.image.publicURL}
              alt="League Night"
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                League Information
              </h1>
              <p className="text-lg leading-7 text-gray-500 mb-5">
                Ready to take your skills to the next level? Leagues are the
                perfect way to improce your axe skills, meet new people, and
                compete to become Champion!
              </p>
              <div className="prose text-gray-500">
                <p>
                  Leagues are an individial competition, unless you are playing
                  Duals. 
                </p>
                <h2>League Highlights</h2>
                <ul>
                  <li>All Skill levels can participate</li>
                  <li>Entry is $100 + tax per person/season</li>
                  <li>8 weeks of competition</li>
                  <li>Warm-up sessions provided weekly</li>
                  <li>Pays to be a winner!</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default League
